import React from "react";
import Footer from "./Footer";
import { Header } from "./Header";
import { textContent, titleContent } from "../Styles";
import { DownArrowIcon } from "../buttons/Buttons";
import { HomeNavigator } from "../util/HomeNavigator";

export default function WhatWeDo() {
  return (
    <div className=" fixed inset-0 w-screen h-screen flex flex-col overflow-hidden">
      <div>
        <Header />
      </div>
      <HomeNavigator id="homeWhatWeDo"/>
      <div className="w-full h-full  cssanimation sequence fadeInBottom overflow-y-auto h-full overflow-x-hidden">
        <div className="what_we_do_logo h-full text-white p-8 flex flex-col justify-between">
          <div className={`${titleContent} pt-1`}>
            <strong>This Is</strong> what <strong>We Do</strong> best
          </div>
          <div
            className={`${textContent} flex flex-row items-center gap-6 justify-end`}
          >
            <strong> OUR SERVICES </strong>{" "}
            <a target="_blank"  href="#services_view">
              <span>
                <DownArrowIcon />
              </span>
            </a>
          </div>
        </div>
        <div
          id="services_view"
          className="w-full h-fit p-8 py-4  flex flex-col gap-4 items-center "
        >
          <div className={`text ${titleContent}`}>Discover Our Range</div>
          
          <div className="relative mobile:hidden right-[-120px] largeScreen:top-40 w-full largeScreen:mt-5  flex flex-col gap-1 h-full justify-center items-center">

            <div className="absolute  right-[80px] grid place-items-center   bg-[#C7C6C3] bg-opacity-[20%]  rounded-full w-[100px] h-[100px]">
            <div className="absolute  right-[-40px]    bg-[#C7C6C3] bg-opacity-[10%]  rounded-full w-[150px] h-[150px]">
              
              </div>
            </div>
          </div>


          <div className="relative mobile:hidden left-[-120px]  w-full  flex flex-col gap-1 h-full justify-center items-center">
            <div className="absolute  mt-40 left-20 border-2 border-[#5CC82A] border-opacity-[10%]  rounded-full w-[200px] h-[200px]"></div>

            <div className="absolute mt-[450px] left-10   border-[28px] border-[#5CC82A] border-opacity-[10%]  rounded-full w-[200px] h-[200px]"></div>
          </div>

          <div className="flex flex-col items-center w-full gap-4 ">
            <div
              className={` bg-[#0B8909DE] hover:scale-[1.05] hover:bg-green-100 cursor-pointer h-fit w-full  max-w-[500px] rounded-[42.56px] p-4 flex flex-row items-center gap-6`}
            >
              <div className="p-4 py-2 rounded-full flex justify-center font-bold  items-center bg-[#ECD50ACC] text-[#086706] ">
                1
              </div>
              <div className=" rounded-[20px]">
                I’m Vajrāstra V2 and IOT device designed with state-of-the-art
                technology which will bring your water and crop management to
                cloud
              </div>
            </div>
            <div
              className={` bg-[#0B8909DE] hover:bg-green-100 cursor-pointer h-fit w-full max-w-[500px] rounded-[42.56px] p-4 flex flex-row items-center gap-6`}
            >
              <div className="p-4 py-2 rounded-full flex justify-center font-bold  items-center bg-[#ECD50ACC] text-[#086706] ">
                2
              </div>
              <div className=" w-[500px] p-2 rounded-[20px]">
                I’m Rudrāstra and Electronic device designed with
                state-of-the-art technology which will enable controlling of
                your Pump-Set with Remote
              </div>
            </div>
            <div
              className={` bg-[#0B8909DE] hover:bg-green-100 cursor-pointer h-fit w-full max-w-[500px] rounded-[42.56px] p-4 flex flex-row items-center gap-6`}
            >
              <div className="p-4 py-2 rounded-full flex justify-center font-bold  items-center bg-[#ECD50ACC] text-[#086706] ">
                3
              </div>
              <div className=" w-[500px] p-2 rounded-[20px]">
                Applications of IOT in agriculture target conventional farming
                operations to meet the increasing demands and decrease
                production loses
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
