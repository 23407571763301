import React from "react";
import { textContent, titleContent } from "../../Styles";
import { WhatWeDoButton } from "../../buttons/Buttons";

export default function MotorIntro() {
  return (
    <div className="bg_intro_img_flower  w-full h-fit">
      <div className="cssanimation sequence fadeInBottom text-white flex flex-col  gap-5 h-full w-full p-8">
      <div className={`${titleContent}   w-[60%] font-[200] mobile:w-full ipadMin:w-full`}>
        Brings <span className="font-semibold">Motor</span> In The <span className="font-semibold">Field</span> To{" "}
        <span className="font-semibold">Cloud</span>
      </div>

      <div className="relative mobile:hidden right-[-120px] largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col gap-1 h-full justify-center items-center">
        <div className="absolute right-10   border-[24px] border-[#FBE204] border-opacity-[20%]  rounded-full w-[200px] h-[200px]"></div>

        <div className="absolute mt-60 right-10 border-2 border-[#FBE204] border-opacity-[20%]  rounded-full w-[200px] h-[200px]"></div>
      </div>
      <div className="w-fit flex flex-row items-end mobile:flex-col ipadMin:flex-col mobile:items-center ipadMin:items-center gap-10">
        <div className={`${textContent}  w-[60%] mobile:w-full ipadMin:w-full`}>
          In current years, the Internet of Things’ benefits, inventions,
          advancements, and all sorts of applications have been a common topic
          in the mass media. THEJA TECHNOLOGIES can be your supportive buddy for
          IoT services. Internet Of Things (IoT services) helps people grow and
          develop their businesses and decreases human labor.
        </div>
        <div>
          <WhatWeDoButton />
        </div>
      </div></div>
    </div>
  );
}
