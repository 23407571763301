import React, { useContext } from "react";
import { textContent, titleContent } from "../../Styles";
import { ReadMore } from "../../buttons/Buttons";
import { useNavigate } from "react-router-dom";
import { ActiveContext } from "../../App";

export default function ExploreOurProducts() {
  const navigate = useNavigate();
  const [active, setActive] = useContext(ActiveContext);

  const rudrastraHandler = () => {
    setActive("rudrastra");
    navigate("/rudrastra");
    localStorage.setItem('productBack',"home")
  };

  const vajrastraHandler = () => {
    setActive("vajrastra");
    navigate("/vajrastra");
    localStorage.setItem('productBack',"home")
  };
  return (
    <div className="explore_our_products cssanimation sequence fadeInBottom p-8 w-full h-fit text-white flex flex-col mobile:items-center ipadMin:items-center">
      <div
        className={`${titleContent} w-full text-center pb-8 mt-[-40px] font-[200]`}
      >
        <span className="font-semibold">Explore</span> Our{" "}
        <span className="font-semibold">Products</span>
      </div>
      <div className="w-full grid grid-cols-2 mobile:grid-cols-1 ipadMin:grid-cols-1 gap-12 place-items-center">
        <div className="flex flex-col gap-10 items-center px-4 ">
          <div className="flex flex-col gap-8  ">
            <div
              className={`${textContent} mobile:max-w-[450px]  ipadMin:max-w-[500px]`}
            >
              I’m Vajrāstra V2 and IOT device designed with state-of-the-art
              technology which will bring your Pump-Set to cloud
            </div>
            <div className="w-fit" onClick={vajrastraHandler}>
              <ReadMore />
            </div>
          </div>
          <div className=" w-[380px] h-[220px] vajrastra_logo  p-4 flex flex-row justify-end  ">
            <strong className="text-right p-2 w-[85%] text-[1.5rem]">
              VAJRASTRA
            </strong>
          </div>
        </div>
        <div className="flex flex-col items-center p-4 mobile:flex-col-reverse ipadMin:flex-col-reverse gap-8 laptop:border-l ipadMax:border-l largeScreen:border-l mobile:border-t ipadMin:border-t ">
          <div className=" w-[380px] h-[220px] rudrastra_logo  p-4 flex flex-row justify-start   ">
            <strong className="p-2 px-4  text-left w-[85%] text-[1.5rem]">
              RUDRASTRA
            </strong>
          </div>
          <div className="flex flex-col gap-5 ">
            <div
              className={`${textContent} mobile:max-w-[450px]  ipadMin:max-w-[500px]`}
            >
              I’m Rudrāstra and Electronic device designed with state-of-the-art
              technology which will enable controlling of your Pump-Set with
              Remote
            </div>
            <div onClick={rudrastraHandler}>
              <ReadMore />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
