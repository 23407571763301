import React, { useContext, useEffect } from "react";
import { Header } from "./Header";
import { textContent, titleContent } from "../Styles";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import { ActiveContext } from "../App";
import { HomeNavigator } from "../util/HomeNavigator";


export default function PrivacyPolicy() {
  const [active,setActive]=useContext(ActiveContext)
  const location=useLocation()
  useEffect(()=>{
    if (location.pathname==="/privacy-policy") {
     setActive("privacy")
    }
  })
  return (
    <div className="fixed inset-0 w-screen h-screen flex flex-col overflow-hidden">
      <Header />
      <div className=" w-full cssanimation sequence fadeInBottom text-gray-500  h-full tracking-wider overflow-y-auto h-full overflow-x-hidden ">
      <HomeNavigator id="footer"/>
        <div className="min-h-full h-fit flex flex-col mobile:mt-4  gap-5 items-center w-full mobile:px-2 px-10">
       
   
      
          <div className="border-b-2 border-[#104D11]  w-full mobile:w-[90%] text-center ">
            <h1 className={`${titleContent} scale-[0.6] text-top `}>PRIVACY POLICY</h1>
          </div>

          <div
            className={`${textContent}   mx-10 flex flex-col gap-4`}
          >
            <span>
              This privacy notice for Theja Technologies Private Limited
              ('Company', 'we', 'us', or 'our'), describes how and why we might
              collect, store use, and /or share ('Process') your information
              when you use our services ('services'), such as when you:
            </span>
            <ul className="ml-10 list-disc ">
              <li>
                Download and use our mobile application (Theja console), or any
                other application of ours that links to this privacy notice
              </li>
              <li>
                Engage with us in other related ways, include any sales,
                marketing, or events
              </li>
            </ul>
            <span>
              Questions or concerns? Reading this privacy notice will help you
              understand your privacy rights and choices. If you do not agree
              with our policies and practices, please do not use our Services.
              If you still have any questions or concerns, please contact us at
              queries@theja.in
            </span>
          </div>
          <div className="relative mobile:hidden left-0 largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
        <div className="absolute left-[-100px] mt-[-500px] bg-[#50F1043D] border-opacity-[40%]  rounded-full w-[100px] h-[100px]  grid place-items-center">
          <div className="   bg-[#54FB0526] border-opacity-[40%]  rounded-full w-[80px] h-[90px]"></div>
        </div>
      </div>
      <div className="relative mobile:hidden largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
          <div className="absolute right-[-100px] mt-[400px]  border-[24px] border-[#50F1043D] border-opacity-[40%]  rounded-full w-[150px] h-[150px]">
          </div>
        </div>
        <div className="relative mobile:hidden largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
          <div className="absolute left-[-100px] mt-[1800px] border-[24px] border-[#50F1043D] border-opacity-[40%]  rounded-full w-[150px] h-[150px]">
          </div>
        </div>

          <div
            className={`w-full text-left laptop:mt-[-80px] largeScreen:mt-[-120px] ipadMin:mt-[-50px] mobile:mt-0 px-10 uppercase ${textContent} font-bold laptop:text-[1.8rem] text-[#1F6D04]`}
          >
            <h1>SUMMARY OF KEY POINTS</h1>
          </div>
          <div
            className={`${textContent}  text-gray-500 mx-10 flex flex-col gap-4`}
          >
            <span>
              This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by using our
              table of contents below to find the section you are looking for.
            </span>
          
            <div className="text-[#1F6D04] font-semibold " id="what-info-do-we-collect">
              <h1>1. What information do we collect?</h1>
            </div>
            <div className="flex flex-col gap-4">
              <span>
                {" "}
                Personal information you disclose to us <br />
                Sensitive Information. We do not process sensitive information.{" "}
                <br />
                Payment Data. We may collect data necessary to process your
                payment if you make purchases, such as your payment instrument
                number, and the security code associated with your payment
                instrument. All payment data is stored by "Vendor Name". You may
                find their privacy notice link(s) here: "vendor privacy policy
                URL".
              </span>
              <span>
                Application Data. If you use our application(s), we also may
                collect the following information if you choose to provide us
                with access or permission:
              </span>
              <ul className="list-disc ml-10">
                <li>
                  Push Notifications. We may request to send you push
                  notifications regarding your account or certain features of
                  the application(s). If you wish to opt out from receiving
                  these types of communications, you may turn them off in your
                  device's settings
                </li>
              </ul>
              <span>
                This information is primarily needed to maintain the security
                and operation of our application(s), for troubleshooting, and
                for our internal analytics and reporting purposes.
              </span>
              <span>
                All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to
                such personal information.
              </span>
            </div>
            <div className="text-[#1F6D04] font-semibold " id="Who-are-allowed-to-use-our-application?">
              <h1>2. Who are allowed to use our application?</h1>
            </div>
            <div>
              Our mobile application (the "Theja Console") is designed for use
              by individuals who are at least 14 years of age or older. By
              downloading or using the App, you confirm that you are at least 14
              years of age or older.
            </div>

            <div className="text-[#1F6D04] font-semibold ">
              <h1>3. Control for DO-NOT-TRACK feature.</h1>
            </div>
            <div>
              We do not have any functionality to access users’ location from
              our application.
            </div>

            <div className="text-[#1F6D04] font-semibold ">
              <h1>4. Do we make updates to this policy?</h1>
            </div>
            <div>
              We may update this privacy policy from time to time. The updated
              version will be indicated by an updated 'Revised' date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </div>

            <div className="text-[#1F6D04] font-semibold ">
              <h1>5. How can you contact us about this notice?</h1>
            </div>
            <div>
              If you have questions or comments about this notice, you may email
              us at 
              <span
                className="underline hover:text-black cursor-pointer"
                onClick={() =>
                  window.open(
                    "mailto:support@example.com?subject=SendMail&body=Description"
                  )
                }
              >
                hello@theja.in
              </span>
                or 
              <span className="hover:underline cursor-pointer hover:text-black">
                +91 9676316164
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

