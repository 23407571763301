import React from "react";
import { green_color, textContent, titleContent } from "../../Styles";

export default function VajrastraHelpToWork() {
  return (
    <div className={`flex flex-col gap-5 w-full h-fit p-8 ${green_color}`}>
      <div
        className={`${titleContent} font-[200] border-t text border-[#2F6A13]`}
      >
        {" "}
        <span className="font-semibold">What</span> Do{" "}
        <span className="font-semibold">I </span>Need To{" "}
        <span className="font-semibold">Work Properly</span>
      </div>
      <div className="w-full px-8">
        <ol className={`list-disc ${textContent} text`}>
          <li>I just need good mobile network coverage</li>
          <li>A working SIM card with active data connection</li>
        </ol>
      </div>
    </div>
  );
}
