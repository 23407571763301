import React from 'react'
import { titleContent } from '../../Styles'
import { HomeNavigator } from '../../util/HomeNavigator'

export default function RudrastraIntro() {
  return (
    <div className={`product_bg p-10 text-white pt-12 ${titleContent}`}>
RUDRASTRA</div>
  )
}
