import React, { createContext, useEffect, useState } from "react";
import { HashRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./components/Home/Home";
import AboutScreen from "./components/about/AboutScreen";
import RudrastraMain from "./components/Rudrastra/RudrastraMain";
import VajrastraMain from "./components/Vajrastra/VajrastraMain";
import WhatWeDo from "./components/WhatWeDo";
import ReadMore from "./components/ReadMore";
import ContactUs from "./components/ContactUs";
import SuccessMessageForm from "./components/SuccessMessageForm";
import PrivacyPolicy from "./components/PrivacyPolicy";
import CookiePolicy from "./components/CookiePolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import Careers from "./components/Careers";

export const ActiveContext=createContext(null)
function App() {
  const [active,setActive]=useState("")
  const [prevScreen,setPrevScreen]=useState([])

  return (
    <ActiveContext.Provider value={[active,setActive,prevScreen,setPrevScreen]}>
     <HashRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<AboutScreen />}></Route>
        <Route path="/rudrastra" element={<RudrastraMain />}></Route>

        <Route path="/vajrastra" element={<VajrastraMain />}></Route>
        <Route path="/what-we-do" element={<WhatWeDo />}></Route>

        <Route path="/read-more" element={<ReadMore />}></Route>
        <Route path="/contact-us" element={<ContactUs />}></Route>
        <Route path="/form-submitted" element={<SuccessMessageForm />}></Route>

        <Route path="/privacy-policy" element={<PrivacyPolicy />} ></Route>
      <Route path="/cookie-policy" element={<CookiePolicy />} ></Route>
      <Route path="/terms-conditions" element={<TermsAndConditions />} ></Route>
      <Route path="/careers" element={<Careers />} ></Route>

      </Routes>
     </HashRouter>
     </ActiveContext.Provider>
  );
}

export default App;
