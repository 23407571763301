import React, { useContext, useEffect } from "react";
import { Header } from "../Header";
import Footer from "../Footer";
import WhoAmIVajrastra from "./WhoAmIVajrastra";
import VajrastraIntro from "./VajrastraIntro";
import { useLocation } from "react-router-dom";
import { ActiveContext } from "../../App";
import { HomeNavigator } from "../../util/HomeNavigator";
import VajrastaProblemSolve from "./VajrastraProblemSolve";
import VajrastraHowCanAccess from "./VajrastraHowCanAccess";
import VajrastraHelpToWork from "./VajrastraHelpToWork";

export default function VajrastraMain() {
  const location = useLocation();
  const [active, setActive] = useContext(ActiveContext);
  useEffect(() => {
    if (location.pathname === "/vajrastra") {
      setActive("vajrastra");
    }
  });
  useEffect(() => {
    if (localStorage.getItem("idScroll") !== null) {
      document
        .getElementById(
          localStorage.getItem("idScroll")
            ? localStorage.getItem("idScroll")
            : ""
        )
        .scrollIntoView({ behavior: "instant" });
    }
    localStorage.removeItem("idScroll");
  }, []);
  return (
    <div className="fixed inset-0 w-screen h-screen flex flex-col overflow-hidden">
      <div>
        <Header />
      </div>
      {localStorage.getItem("productBack") === "home" && (
        <HomeNavigator id="products" />
      )}{" "}
      <div className="w-full cssanimation sequence fadeInBottom tracking-wider h-full overflow-y-auto h-full overflow-x-hidden">
        <VajrastraIntro />
        <WhoAmIVajrastra />
        <VajrastaProblemSolve />
        <VajrastraHowCanAccess />
        <VajrastraHelpToWork />
        <div id="footer">
          {" "}
          <Footer />
        </div>
      </div>
    </div>
  );
}
