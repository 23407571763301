import React, { useContext, useEffect } from 'react'
import { Header } from '../Header'
import MotorIntro from './MotorIntro'
import IotAgriculture from './IotAgriculture'
import ExploreOurProducts from './ExploreOurProducts'
import IOtServiceProvide from './IOtServiceProvide'
import OurClientSay from './OurClientSay'
import Footer from '../Footer'
import { useLocation, useParams } from 'react-router-dom'
import { ActiveContext } from '../../App'

export default function Home() {
 const [active,setActive]=useContext(ActiveContext)
  const location=useLocation();
  useEffect(()=>{
    if (location.pathname==="/"||location.pathname==="/#/") {
      setActive('home')
    }
  })

  useEffect(()=>{
    if (localStorage.getItem('idScroll')!==null) {
     document.getElementById(localStorage.getItem('idScroll')?localStorage.getItem('idScroll'):"").scrollIntoView({ behavior: "instant" })
    }
      localStorage.removeItem('idScroll');
  },[])
  return (
    <div className='fixed inset-0 w-screen h-screen flex flex-col overflow-hidden'>
    <div>
    <Header />
    </div>
         <div className='w-full h-full overflow-y-auto tracking-wider h-full overflow-x-hidden'>
          <div id='homeWhatWeDo'><MotorIntro /></div>
          <div id='smartForming'><IotAgriculture /></div>
          <div id='products'> <ExploreOurProducts /></div>
          <div><IOtServiceProvide /></div>
          <div><OurClientSay /></div>
          <div id='footer'> <Footer /></div>
         
          
         
          
          
         
         </div>
       </div>
  )
}
