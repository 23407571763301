import { useContext, useEffect, useState } from "react";
import {useNavigate} from 'react-router-dom'
import { bg_green_linear_color } from "../Styles";
import { ActiveContext } from "../App";
export const Header = () => {
  const [ismobileMenu, setMenu] = useState(false);
  const navigate=useNavigate();
  const [active,setActive]=useContext(ActiveContext)


  const homeHandler=()=>{
    navigate("/")
    setActive("home")
    localStorage.clear()
  }
  const vajrastraHandler=()=>{
    navigate("/vajrastra")
    setActive("vajrastra")
    localStorage.clear()

  }
  const rudrastraHandler=()=>{
    navigate("/rudrastra")
    setActive("rudrastra")
    localStorage.clear()

  }
  const contactHandler=()=>{
    navigate("/contact-us")
    setActive("contactus")
    localStorage.clear()

  }
  const aboutHandler=()=>{
    navigate("/about")
    setActive("about")
    localStorage.clear()

  }
  return (
    <div className={`${bg_green_linear_color}  w-full relative text-white w-full scale-[1] h-[10vh] flex flex-row items-center px-4`}>
  
  <div
          onClick={homeHandler}
          className="w-fit h-full cursor-pointer hover:text-gray-300 flex flex-row items-center justify-center  text-[60px]  font-bold text-[#ffffff] p-1"
        >
          <img
            className="w-[90%] h-[90%]"
            src={require("../assets/main_logo.png")}
            alt=""
          />{" "}
          <span className="-ml-[10px] text-[3rem] rounded-full ">.</span>{" "}
        </div>      <div className="w-[100%] h-fit flex flex-row justify-end">
      <div className="w-fit flex flex-row mobile:hidden ipadMin:hidden largeScreen:gap-10 largeScreen:text-[1.1rem] laptop:text-[1rem] gap-8 ipadMax:text-[1rem] mobile:text-[0.8rem] uppercase">
        <span className={`cursor-pointer hover:scale-[1.1] hover:font-bold ${active==="home" && "underline "}  text-[#fff]`} onClick={homeHandler}>
              home
            </span>
            <span className={`cursor-pointer hover:scale-[1.1]  hover:font-bold ${active==="vajrastra" && "underline"}  text-[#fff]`} onClick={vajrastraHandler}>
            VAJRASTRA
            </span>
            <span className={`cursor-pointer hover:scale-[1.1]  hover:font-bold ${active==="rudrastra" && "underline"}  text-[#fff]`} onClick={rudrastraHandler}>
            RUDRASTRA
            </span>
         

            <span className={`cursor-pointer hover:scale-[1.1]  hover:font-bold ${active==="contactus" && "underline"}  text-[#fff]`} onClick={contactHandler}>
            CONTACT US
            </span> 
            <span className={`cursor-pointer hover:scale-[1.1]  hover:font-bold ${active==="about" && "underline"}  text-[#fff]`} onClick={aboutHandler}>
            ABOUT US
            </span>
          
        </div>
        {ismobileMenu ? (
          <button
            type="button"
            onClick={() => setMenu(false)}
            className=" rounded-ipadMin  cursor-pointer ipadMin:inline-flex hidden mobile:inline-flex text-gray-400 hover:text-gray-500  focus:outline-none"
          >
            <span className="sr-only">Close menu</span>
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        ) : (
          <button
            onClick={() => setMenu(true)}
            type="button"
            className="w-auto h-auto cursor-pointer ipadMin:inline-flex hidden mobile:inline-flex"
          >
            <svg
              className="w-5 h-5 cursor-pointer hover:font-bold"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        )}
      </div>
      {ismobileMenu && (
        <div
          className="absolute right-0 h-fit z-50  hidden mobile:flex ipadMin:flex flex-row justify-end top-[10vh]"
          id="navbar-default"
        >
          <ul className="bg-black relative bg-opacity-[90%]  px-4 p-2 flex flex-col  items-left uppercase gap-2">
            <li
              className="cursor-pointer relative  min-w-[150px] max-w-[300px]  py-2 px-3 text-white hover:text-red-500 focus:text-red-500 hover:underline rounded bg-transparent  hover:font-bold ipadMin:p-0 "
              aria-current="page"
             onClick={homeHandler}
            >
              Home
            </li>
            <li
              className="cursor-pointer relative min-w-[150px] max-w-[300px]  py-2 px-3 text-white rounded bg-transparent focus:text-red-500 hover:text-red-500 hover:underline hover:font-bold"
              aria-current="page"
              onClick={vajrastraHandler}
            >
              VAJRASTRA
            </li>
            <li
              className="cursor-pointer relative  min-w-[150px] max-w-[300px]  py-2 px-3 text-white rounded bg-transparent focus:text-red-500 hover:text-red-500 hover:underline hover:font-bold"
              aria-current="page"
              onClick={rudrastraHandler}
            >
              RUDRASTRA
            </li>
            <li
              className="cursor-pointer  min-w-[150px] max-w-[300px]  py-2 px-3 text-white  rounded bg-transparent focus:text-red-500 hover:text-red-500 hover:underline hover:font-bold"
              aria-current="page"
              onClick={contactHandler}
            >
              CONTACT US
            </li>
            <li
              className="cursor-pointer min-w-[150px] max-w-[300px]  py-2 px-3 text-white  rounded bg-transparent focus:text-red-500 hover:text-red-500 hover:underline hover:font-bold"
              aria-current="page"
              onClick={aboutHandler}
            >
             ABOUT US
            </li>
          </ul>
        </div>
      )}
  </div>  );
};
