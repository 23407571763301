import React from "react";
import { textContent, titleContent } from "../../Styles";

export default function OurClientSay() {
  return (
    <div className="our_client_say_img  h-fit  w-full ">
      <div className="w-full h-fit bg-black bg-opacity-40 flex flex-col gap-5 items-center text-white p-8">
        <div className={`${titleContent}`}>
          Why <strong>Trust</strong> Us?
        </div>

        <div
          className={`grid grid-cols-4 mobile:grid-cols-2 ipadMin:grid-cols-2  place-items-center text-center gap-12 ${textContent} scale-[0.9]`}
        >
          <div className=" p-2 hover:scale-[1.01] cursor-pointer   rounded-[20px] w-[250px] flex flex-col items-center justify-center">
            <img src={require("../../assets/home/quality_logo.png")} alt="" />
            <strong>Quality Service</strong>
          </div>
          <div className="hover:scale-[1.01] cursor-pointer p-2 rounded-[20px]  w-[250px] flex flex-col items-center justify-center">
            <img src={require("../../assets/home/Gears.png")} alt="" />
            <strong>Flexi Engagement</strong>
          </div>{" "}
          <div className="hover:scale-[1.01] cursor-pointer p-2 rounded-[20px] w-[250px] flex flex-col items-center justify-center">
            <img src={require("../../assets/home/Money Bag.png")} alt="" />
            <strong>Cost Effective</strong>
          </div>{" "}
          <div className="hover:scale-[1.01] cursor-pointer p-2 rounded-[20px] w-[250px] flex flex-col items-center justify-center">
            <img src={require("../../assets/home/Headset.png")} alt="" />
            <strong>Support 24/7</strong>
          </div>
        </div>
      </div>
    </div>
  );
}
