import React from 'react'
import { green_color, textContent, titleContent } from '../../Styles'

export default function HelpToWork() {
  return (
    <div className={`flex flex-col gap-5 w-full h-fit p-8 ${green_color}`}>
    <div className={`${titleContent} font-[200] border-t text border-[#2F6A13]`}> <span className='font-semibold'>What</span> Do <span className='font-semibold'>I </span>Need To <span className='font-semibold'>Work Properly</span></div>
    <div className='w-full px-8'>

<ol className={`list-disc ${textContent} text`}>
    <li>I just need charge like your mobile</li>
    <li>I can control your Pump-Set within 2 Kilo Meter radius</li>
    <li>I can reach up to 5KM if you place the Pump-Set device Antenna at good height</li>
</ol>

    </div>
</div>
  )
}
