import React, { useContext, useEffect, useState } from "react";
import Footer from "../Footer";
import { Header } from "../Header";
import RudrastraIntro from "./RudrastraIntro";
import WhoAmI from "./WhoAmI";
import ProblemSolve from "./ProblemSolve";
import HowCanAccess from "./HowCanAccess";
import HelpToWork from "./HelpToWork";
import { useLocation } from "react-router-dom";
import { ActiveContext } from "../../App";
import { HomeNavigator } from "../../util/HomeNavigator";

export default function RudrastraMain() {
  const location = useLocation();
  const [active, setActive] = useContext(ActiveContext);
  useEffect(() => {
    if (location.pathname === "/rudrastra") {
      setActive("rudrastra");
    }
  });
  useEffect(() => {
    if (localStorage.getItem("idScroll") !== null) {
      document
        .getElementById(
          localStorage.getItem("idScroll")
            ? localStorage.getItem("idScroll")
            : ""
        )
        .scrollIntoView({ behavior: "instant" });
    }
    localStorage.removeItem("idScroll");
  }, []);
  return (
    <div className="fixed inset-0 w-screen h-screen flex flex-col overflow-hidden">
      <div>
        <Header />
      </div>
      {localStorage.getItem("productBack")==="home" && <HomeNavigator id="products" />}{" "}
      <div className="w-full h-full cssanimation sequence fadeInBottom tracking-wider overflow-y-auto h-full overflow-x-hidden">
        <RudrastraIntro />
        <WhoAmI />
        <ProblemSolve />
        <HowCanAccess />
        <HelpToWork />
        <div id="footer">
          {" "}
          <Footer />
        </div>
      </div>
    </div>
  );
}
