import React from "react";
import { ContactUs } from "../../buttons/Buttons";
import { green_color, textContent, titleContent } from "../../Styles";

export default function WhoAmI() {
  return (
    <div
      className={`w-full  h-fit grid grid-cols-2 mobile:grid-cols-1 ${green_color} ipadMin:grid-cols-1 p-8`}
    >
      <div className="h-fit grid place-items-center rounded-[20px]">
        <img
          className="h-[500px] object-cover "
          src={require("../../assets/home/rudrastra.png")}
          alt=""
          srcset=""
        />
      </div>
      <div
        className={`${textContent} p-8  w-full flex flex-col items-center mobile:h-fit mobile:gap-10 justify-between`}
      >
        <div className="flex flex-col gap-4 items-center px-10 mobile:px-0">
          <div>
            <span className={`${titleContent} font-semibold`}>Who Am I?</span>
          </div>
          <div className="font-[450]">
            I’m Rudrāstra and Electronic device designed with state-of-the-art
            technology which will enable controlling of your Pump-Set with
            remote
          </div>
        </div>
        <div className="flex flex-col gap-4 items-center px-10 mobile:px-0 h-fit">
          <div className="text-[40px] font-semibold pb-5">Want To Purchase</div>
          <div>
            <ContactUs />
          </div>
        </div>
      </div>
    </div>
  );
}
