import React from 'react'
import { green_color, textContent, titleContent } from '../../Styles'

export default function HowCanAccess() {
  return (
    <div className={`flex flex-col gap-5 w-full h-fit p-8 ${green_color}`}>
    <div className={`${titleContent} font-[200] border-t border-[#2F6A13]`}>How Can <span className='font-semibold'>You Access </span>Me?</div>
    <div className='w-full px-8'>

<ol className={`list-disc ${textContent}`}>
    <li>A simple remote where you can carry with you to control your Pump-Set</li>
</ol>

    </div>
</div>
  )
}
