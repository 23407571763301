import React from "react";
import { green_color, textContent, titleContent } from "../../Styles";

export default function VajrastraHowCanAccess() {
  return (
    <div className={`flex flex-col gap-5 w-full h-fit p-8 ${green_color}`}>
      <div className={`${titleContent} font-[200] border-t border-[#2F6A13]`}>
        How Can <span className="font-semibold">You Access </span>Me?
      </div>
      <div className="w-full px-8">
        <ol className={`list-disc ${textContent}`}>
          <li>
            {" "}
            Have a dedicated mobile app in Play store and App store for android
            and iphone.
          </li>
          <li>Have a dedicated website where we can access everything</li>
          <li>Can be accessed using Telegram BOT</li>
          <li>Can be accessed using WhatsApp</li>
          <li>Can be controlled using mobile call/IVRS</li>
          <li>Can be controlled through standard message</li>
        </ol>
      </div>
    </div>
  );
}
