import React from "react";
import { green_color, textContent, titleContent } from "../../Styles";
import { ContactUs } from "../../buttons/Buttons";

export default function WhoAmIVajrastra() {
  return (
    <div
      className={`w-full  h-fit grid grid-cols-2 mobile:grid-cols-1 ${green_color} ipadMin:grid-cols-1 p-8`}
    >
      <div className="h-fit grid place-items-center rounded-[20px]">
        <img
          className="h-[500px] "
          src={require("../../assets/products/vajratra-logo.png")}
          alt=""
          srcset=""
        />
      </div>
      <div
        className={`${textContent} p-8  w-full flex flex-col items-center mobile:h-fit mobile:gap-10 justify-between`}
      >
        <div className="flex flex-col gap-4 items-center px-10 mobile:px-0">
          <div>
            <span className={`${titleContent} font-semibold`}>Who Am I?</span>
          </div>
          <div className="font-[400]">
            I’m Vajrāstra V2 and IOT device designed with state-of-the-art
            technology which will bring your Pump-Set to cloud
          </div>
        </div>
        <div className="flex flex-col gap-4 items-center px-10 mobile:px-0 h-fit">
          <div className="text-[40px] font-semibold pb-5">Want To Purchase</div>
          <div>
            <ContactUs />
          </div>
        </div>
      </div>
    </div>
  );
}
