import { useContext, useEffect } from "react";
import Footer from "../Footer";
import { Header } from "../Header";
import AboutProduct from "./AboutProduct";
import WhoWeAre from "./WhoWeAre";
import { useLocation } from "react-router-dom";
import { ActiveContext } from "../../App";



export default function AboutScreen() {
  
  const location=useLocation();
 const [active,setActive]=useContext(ActiveContext)
  useEffect(()=>{
    if (location.pathname==="/about") {
setActive("about")    
}
  })
  useEffect(()=>{
    if (localStorage.getItem('idScroll')!==null) {
     document.getElementById(localStorage.getItem('idScroll')?localStorage.getItem('idScroll'):"").scrollIntoView({ behavior: "instant" })
    }
      localStorage.removeItem('idScroll');
  },[])
    return (
      <div className='fixed inset-0 w-screen h-screen flex flex-col overflow-hidden'>
      <div>
      <Header />
      </div>
           <div className='w-full h-full cssanimation sequence fadeInBottom tracking-wider overflow-y-auto h-full overflow-x-hidden'>
           <WhoWeAre />
           <AboutProduct />
           <div id='footer'> <Footer /></div>
           </div>
         </div>
    )
  }
  