import React from 'react'
import { textContent } from '../../Styles'

export default function AboutProduct() {
  return (
    <div className='w-full h-fit grid grid-cols-[36%_60%] mobile:grid-cols-1 ipadMin:grid-cols-1 place-items-center gap-10 p-8'>
      <div >
        <img className='' src={require('../../assets/about/steering_wheel.png')} alt="" />
      </div>
      <div className={`text-[18px] `}>
      THEJA TECHNOLOGIES platform is the ultimate destination for everyone who looks forward to effective and reliable IoT solutions for the best business scope and opportunities. THEJA TECHNOLOGIES, with the help of IoT services, can help build the image of your company and its brand value. The Internet of Things is one of the most effective ways to run a flawless business with the right security and business operation assistance. THEJA TECHNOLOGIES can be your IoT services platform and can offer reliable and efficacious IoT solutions. THEJA TECHNOLOGIES can be your supportive buddy for IoT services. Internet Of Things (IoT services) helps people grow and develop their businesses and decreases human labor. With IoT services, all business platforms or companies can access and use assets and resources in a better manner. THEJA TECHNOLOGIES can offer reliable IoT services that build the security mechanism of your company.
      </div>
    </div>
  )
}
