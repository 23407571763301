import React from 'react'
import { textContent, titleContent } from '../../Styles'

export default function WhoWeAre() {
  return (
    <div className={`bg_color_green_animation h-fit w-full  laptop:min-h-full w-full laptop:px-8 laptop:py-10 largeScreen:px-12 largeScreen:py-14 p-4 text-white `}>
    <div className={`${titleContent} py-6 border-b w-[80%]`}>Who We Are</div>
    <div className={`${textContent} py-6 w-[75%]`}>We are dedicated to providing outstanding Services, carefully customized to align with your distinct requirements.As a leading service provider, we combine expertise, innovation, and a commitment to excellence to ensure your satisfaction</div>
  </div>
  )
}
