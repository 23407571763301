import React from "react";
import Footer from "./Footer";
import { Header } from "./Header";
import { green_color, textContent, titleContent } from "../Styles";
import { HomeNavigator } from "../util/HomeNavigator";

export default function ReadMore() {
  return (
    <div className=" fixed inset-0 w-screen h-screen flex flex-col overflow-hidden">
      <div>
        <Header />
      </div>
      <div className="w-full h-full overflow-y-auto overflow-x-hidden">
        <HomeNavigator id="smartForming"/>
        <div className="w-full  cssanimation sequence fadeInBottom h-fit p-8 py-4  flex flex-col gap-5items-center ">
          <div className={`text ${titleContent} text-left pt-5`}>
            The <strong>Next Era</strong> Of{" "}
            <strong>Agriculture Intelligence</strong> Has Arrived
          </div>

          <div className="relative mobile:hidden ipadMin:hidden left-[-120px]  w-full  flex flex-col gap-1 h-full justify-center items-center">
            <div className="absolute  mt-40 left-20 border-2 border-[#5CC82A] border-opacity-[10%]  rounded-full w-[150px] h-[150px]"></div>

            <div className="absolute mt-[350px] left-10   border-[28px] border-[#5CC82A] border-opacity-[10%]  rounded-full w-[150px] h-[150px]"></div>
          </div>

          <div className="grid grid-cols-[60%_40%] mobile:place-items-center w-full gap-4 py-6 border-b-[3px]">
            <div className={`${textContent} text `}>
              Using IOT in farming aims to boost productivity , use resources
              more accurately , and encourage sustainability
            </div>
            <div className="p-4 mt-[-40px]">
              <img
                src={require("../assets/products/grass_bg_image.png")}
                alt=""
                srcset=""
              />
            </div>
          </div>
          <div className=" w-full h-fit">
            <div className={`z-40 text ${titleContent} text-left relative`}>
              Benefits of IOT In Agriculture Industry
            </div>
            <div className="w-full h-fit grid grid-cols-[35%_40%_35%] mobile:grid-cols-2  gap-10">
              <div className=" relative w-fit ">
                <div className="absolute w-fit bg-[#9AD080] left-0 p-8 w-[250px] h-[250px] text-white rounded-tr-[400px] rotate-12 mt-[-40px] ml-[-100px] rounded-br-[100px] flex flex-row justify-end items-center">
                  <strong
                    className={`relative -rotate-12 ml-14 text-[#F0FFE4] ${textContent} `}
                  >
                    Smart Agriculture
                  </strong>
                </div>
                <div>
                  <img
                    className="w-[200px] z-40 relative mt-40 ml-[-40px]"
                    src={require("../assets/products/formaer-crops.png")}
                    alt=""
                    srcset=""
                  />
                </div>
              </div>

              <div className=" w-fit pt-10">
                <ul
                  className={`list-disc text-[#1C5A06] font-semibold ${textContent}`}
                >
                  <li>Cost savings</li>
                  <li>Remote Monitoring </li>
                  <li>Increased Efficiency</li>
                  <li>Improved production control</li>
                  <li>Reduce Wastage</li>
                  <li>Less energy consumption</li>
                </ul>
              </div>

      
                 
               <div className="relative z-40  right-10  w-full   flex flex-col gap-1 h-full justify-center items-center">
                <div className="">
                  <img
                    className=" w-[180px] mt-[-150px] ml-[-30px] mobile:ml-40 p-2"
                    src={require("../assets/products/smart-agriculture-iot-with-hand-planting-tree.png")}
                    alt=""
                  />
                </div>
                  <div className="absolute mobile:hidden  grid place-items-center   bg-[#C7C6C3] bg-opacity-[20%]  rounded-full w-[150px] h-[150px]">
                    <div className="absolute  bg-[#C7C6C3] bg-opacity-[10%]  rounded-full w-[200px] h-[200px]"></div>
                  </div>
                </div>
               </div>

          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
