import React from "react";
import { green_color, textContent, titleContent } from "../../Styles";

export default function VajrastaProblemSolve() {
  return (
    <div className={`flex flex-col gap-5 w-full h-fit p-8 ${green_color}`}>
      <div
        className={`${titleContent} text border-t border-[#2F6A13] font-[200]`}
      >
        What <span className="font-semibold">Problems</span> Do I{" "}
        <span className="font-semibold">Solve</span> ?
      </div>
      <div className="w-full grid grid-cols-2 mobile:grid-cols-1 p-8">
        <div>
          <ol className={`list-disc ${textContent} text`}>
            <li>No physical presence required</li>
            <li>Every event is tracked, analysed and will be updated</li>
            <li>Pump runtime can be configured</li>
            <li>Cross platform</li>
            <li>Easy to use</li>
            <li>Easy to track the device status</li>
            <li>Dry run protection</li>
            <li>Overload protection</li>
            <li>
              Continuously monitors the Pump performance, current consumption
              and shuts down if any issues detected
            </li>
            <li>
              All metrics will be published to server continuously to analyse
              the performance and protects the device from burning out
            </li>
            <li>
              Completed track of the Pump run history, Power History and
              performance history for tracking purposes
            </li>
          </ol>
        </div>
        <div className="w-[200px] h-[200px] laptop:ml-5 largeScreen:ml-10 rounded-[20px]">
          <img
            src={require("../../assets/products/starter.png")}
            alt=""
            sizes=""
            srcset=""
          />
        </div>
      </div>
    </div>
  );
}
