import React from "react";
import { green_color, textContent, titleContent } from "../../Styles";
import { ReadMore } from "../../buttons/Buttons";
import { useNavigate } from "react-router-dom";

export default function IotAgriculture() {
  const navigate=useNavigate()
  return (
    <div className={` ${green_color} w-full h-fit p-8 grid grid-cols-2 ipadMin:grid-cols-1 mobile:grid-cols-1 gap-10 place-items-center`}>
      <div className="flex flex-col cssanimation sequence fadeInBottom items-start mobile:items-center ipadMin:items-center gap-2 w-full">
        <div className={`${titleContent}  leading-tight w-full text font-[200]`}>
          The <span className="font-semibold">Future</span> Of{" "}
          <span className="font-semibold">Smart Farming</span> Is Here
        </div>
        <div className=" w-[400px]">
          <img
            src={require("../../assets/home/iot_agriculture_image.png")}
            alt=""
            srcset=""
          />
        </div>
        <div  className="w-full flex flex-row mobile:justify-center ipadMin:justify-center ">
          <div onClick={()=>navigate('/read-more')}><ReadMore /></div>
        </div>
      </div>
      
      <div className={`w-full ${textContent} text tracking-wider`}>
      <div className='relative mobile:hidden right-[-120px] largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col gap-1 h-full justify-center items-center'>
  <div className='absolute right-10  bg-[#29C62B] bg-opacity-[10%]  rounded-full w-[200px] h-[200px]'>
  </div>
      </div>
        THEJA TECHNOLOGIES offers astounding IoT services at affordable rates.
        The main aim of THEJA TECHNOLOGIES is to provide the right IoT solutions
        after understanding the client’s concerns and problems. THEJA
        TECHNOLOGIES platform is the ultimate destination for everyone who looks
        forward to effective and reliable IoT solutions for the best business
        scope and opportunities. THEJA TECHNOLOGIES, with the help of IoT
        services, can help build the image of your company and its brand value.
        The Internet of Things is one of the most effective ways to run a
        flawless business with the right security and business operation
        assistance. THEJA TECHNOLOGIES can be your IoT services platform and can
        offer reliable and efficacious IoT solutions.
      </div>
    </div>
  );
}
