import React from 'react'
import { textContent, titleContent } from '../../Styles'
import { JoinUs } from '../../buttons/Buttons'

export default function IOtServiceProvide() {
  return (
    <div className='iot_service_provide_img  h-fit  w-full '>
        <div className='w-full cssanimation sequence fadeInBottom h-fit bg-black bg-opacity-40 grid grid-cols-2 mobile:grid-cols-1 ipadMin:grid-cols-1 gap-10 place-items-center text-white p-8'>
<div className='flex flex-col gap-10'>
    <div className={`${titleContent}`}><strong>Trustworthy IOT</strong> Service <strong>Provider</strong></div>
    <div className={`${textContent}`}>Internet Of Things (IoT services) helps people grow and develop their businesses and decreases human labor</div>
    <div className=''>
        <JoinUs />
    </div>
</div>
<div className='w-[350px]'>
    <img src={require('../../assets/home/IoT-Applications-img.png')} alt="" />
</div>
        </div>
    </div>
  )
}
